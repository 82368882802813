import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageHeader from "../components/common/PageHeader";
import AppContext from "../context/AppContext";
import MetaData from "../helpers/MetaData";
import { HiOutlineDownload } from "react-icons/hi";
import notification from "../helpers/notification";
import ApiService from "../api/ApiService";
import { useState } from "react";
import moment from "moment";
import fileDownload from "js-file-download";
import GoToTop from "../helpers/GoToTop";
import axios from "axios";

const Dashboard = () => {

  const search = useLocation().search;
  const link_id = new URLSearchParams(search).get("link");


  const { setUser, setToken, user } = useContext(AppContext);
  const navigate = useNavigate();
  const [donations, setDonations] = useState(null);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [lifeMembershipDetails, setLifeMembershipDetails] = useState(null)
  const [purchases, setPurchases] = useState(null)
  const [ics_data, setICSdata] = useState([])
  
  const logOut = () => {
    localStorage.removeItem("token");
    setUser(null);
    setToken(null);
    navigate("/");
    localStorage.removeItem('icsmob');
    localStorage.removeItem('ics_mob');
    localStorage.removeItem('ics_id');
    localStorage.removeItem('multiplesevatype');
    localStorage.removeItem('multiplesevaPrice');
    localStorage.removeItem('multiplesevaName');
    localStorage.removeItem('ics_number')
    
  localStorage.removeItem('ics_all_data');
    
  };

  const fetchUserDonation = async () => {
    try {
      const res = await ApiService.fetchUserDonation(page);
      // console.log(res.donations);
      setLastPage(res.totalPages);
      setDonations(res.donations);
    } catch (err) {
      console.log(err);
      notification("error", err.response.data.message);
    }
  };
  // const handleScrolltoForAllPages = () => {

  //   setTimeout(() => {
  //     const scrollToDonateFromRef = document.getElementById('scrollToAllPages');


  //     if (scrollToDonateFromRef) {
  //       const offset = -600;
  //       const targetScrollTop = scrollToDonateFromRef.offsetTop - offset;

  //       const container = document.documentElement;

  //       container.scrollTop = targetScrollTop;
  //     } else {
  //       console.error("Element scrollToDonateFromRef not found!");
  //     }
  //   }, 1000);
  // };
  

  const handleImgDownload = async (id) => {
    try {
      const res = await ApiService.downloadImg(`${id}.jpg`);
      fileDownload(res, "Annadaan-certificate.jpg");
    } catch (err) {
      console.log(err);
      notification(
        "error",
        "Unable to download certificate, try again after some time!"
      );
    }
  };

  const fetchLifeMembership = async () => {
    try {
      
      const lifeMembership = await ApiService.fetchLifeMembership()
      
      setLifeMembershipDetails(lifeMembership)

    }catch(err) {
      console.log(err)
    }
  }

  const fetchUserPurchases = async (req, res) => {
    try {

      const {purchases} = await ApiService.fetchUserPurchases();
      setPurchases(purchases)

    }catch(err) {
      console.log(err)
    }
  }


 // handleFileDownload(id)

  // const fetchFailedCampaignDetails = async () => {
  //   try {
      
  //     const res = await ApiService.fetchFailedCampaignDetails(id)
  //     // console.log(res)
  //     setCampaignDetails(res)

  //   }catch(err) {
  //     console.log(err)
  //   }
  // }

  // useEffect(() => {
  //     fetchFailedCampaignDetails()
    
  // },[])

  useEffect(() => {
    fetchUserDonation();
    fetchLifeMembership();
    fetchUserPurchases()
  }, [page, user]);
  
  const user_mob = localStorage.getItem('ics_number');

 const fetchFromIcs = async () => {
  const response = await ApiService.getIcsData(user_mob)
  // const url = process.env.REACT_APP_ICS_URL
  // // const url = 'http://localhost:8080/ics/api/actionHandler'
  // const response = await axios.get(url,{
  //     params:{
  //         cn:'donation',
  //         an:'getICSids', //or getDonations
  //         phone: user_mob,
  //         mode: 'DETAILED'    //DETAILED or DETAILED_WITH_DONATIONS or blank
  //     },
  //     withCredentials: true,
  //     auth: {
  //        username: process.env.REACT_APP_ICS_USERNAME,
  //        password: process.env.REACT_APP_ICS_PASSWORD
  //     }
  // });

  setICSdata(response.data);

 // console.log(response.data.find(dat => dat.id === user.icsid).relatives);

}

//console.log(ics_data); 


 const ics_id = localStorage.getItem('ics_id');

 const handelProfiles = async (e) =>{
      // const url = process.env.REACT_APP_ICS_URL
      // // const url = 'http://localhost:8080/ics/api/actionHandler'
      // const response = await axios.get(url,{
      //     params:{
      //         cn:'donation',
      //         an:'getICSids', //or getDonations
      //         phone: user_mob,
      //         mode: 'DETAILED'    //DETAILED or DETAILED_WITH_DONATIONS or blank
      //     },
      //     withCredentials: true,
      //     auth: {
      //        username: process.env.REACT_APP_ICS_USERNAME,
      //        password: process.env.REACT_APP_ICS_PASSWORD
      //     }
      // });

      const response = await ApiService.getIcsData(user_mob)
      //console.log(response.data)
      const ics_dataa = response.data;
      ics_dataa.filter(dat => dat.id==e.target.value).map((item) => (

       localStorage.setItem('ics_id', item.id),
       localStorage.setItem('ics_name', item.name),
       localStorage.setItem('ics_number', item.number),
       notification('success',  item.name + ' set as Default'),
       setTimeout(() => {
       window.location.reload(true);
      }, 3000)

      ))

      
  
      // console.log(response.data.data[0]);
      // localStorage.setItem('ics_id', response.data.data[0].id);
      // localStorage.setItem('ics_name', response.data.data[0].name);
      // localStorage.setItem('ics_number', response.data.data[0].number);
 }


//  const handelProfiless = async (e) =>{
//   const url = process.env.REACT_APP_ICS_URL
//   // const url = 'http://localhost:8080/ics/api/actionHandler'
//   const response = await axios.get(url,{
//       params:{
//           cn:'donation',
//           an:'getICSids', //or getDonations
//           phone: user_mob,
//           mode: 'DETAILED'    //DETAILED or DETAILED_WITH_DONATIONS or blank
//       },
//       withCredentials: true,
//       auth: {
//          username: process.env.REACT_APP_ICS_USERNAME,
//          password: process.env.REACT_APP_ICS_PASSWORD
//       }
//   });

//   console.log(response.data.data[0].relatives)

//   const ics_dataa = response.data.data[0].relatives;
//   ics_dataa.filter(dat => dat.ind1_ICSID==e.target.value).map((item) => (

//    localStorage.setItem('ics_id', item.ind1_ICSID),
//    localStorage.setItem('ics_name', item.ind1_name),
//   // localStorage.setItem('ics_number', item.number),
//    notification('success',  item.name + ' set as Default'),
//    setTimeout(() => {
//    window.location.reload(true);
//   }, 3000)

//   ))

  

  // console.log(response.data.data[0]);
  // localStorage.setItem('ics_id', response.data.data[0].id);
  // localStorage.setItem('ics_name', response.data.data[0].name);
  // localStorage.setItem('ics_number', response.data.data[0].number);
//}



 

useEffect(() => {
  fetchFromIcs();
}, []);

//const relativeData = {
//console.log(user)
//};
// Make sure 'user' exists and 'relatives' is an array
if (!user || !Array.isArray(user[0]?.relatives)) {
  return <div>Loading or Error Message...</div>;
}

//console.log("==>>>", ics_data)



  return (
    <>
      <GoToTop/>
      <MetaData title="Dashboard - ISKCON of Attapur" />
      <section className="dashboard d-none">
        <div className="container">
          <div className="user-details-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="user-details">
                  <ul className="mb-0 list-unstyled">
                    <li className="text-capitalize">Hi {user?.name}</li>
                    {user?.email && (
                      <li>
                        <strong>Email:</strong> {user?.email}
                      </li>
                    )}
                    <li>
                      <strong>Phone:</strong> {user?.phone}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex justify-content-end">
                  <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={() => { navigate("/update-profile") }}
                  >
                    Edit Profile
                  </button>
                  <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={logOut}
                  >
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
      
    </div>

      <section className="dashboard-table">
        <div className="container">
          <div className="top-head pb-3 pb-lg-0 heading">
            <h2 className="head-1"></h2>
            <div className="d-flex justify-content-center flex-wrap">
            <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={() => navigate("/Service-requests")}
                  >
                    Service Requests
                  </button>
                  <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={() => navigate("/join-family")}
                  >
                    Join Family
                  </button>
                  <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={() => navigate("/All-Transactions")}
                  >
                   All Transactions
                  </button>
                  <button
                    className="custom-btn-cls box-hover"
                    onClick={() => navigate("/donation-form")}
                  >
                    Donate more
                  </button>
                  {/* <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={logOut}
                  >
                    Log out
                  </button> */}
            </div>
          </div>
          
            
            <div className="life-membership-table">
              <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Profiles</h5>
              <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-lg-3">
                    <h5>Set as Default</h5>
                  </div>
                  <div className="col-lg-3">
                    <h5>Name</h5>
                  </div>
                  <div className="col-lg-3">
                    <h5>Address</h5>
                  </div>
                  <div className="col-lg-3">
                    <h5>Action</h5>
                  </div>
                  
                </div>
              </div>
              <div className="table-content pt-2 pt-lg-0">
                <div className="row">
                  {ics_data?.map((item, i) => (
                    <div key={i} className="col-lg-12 col-md-6 ">
                      <div className="table-row py-lg-4 py-3">
                        <div className="row align-items-center">
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Set as Default :</h5>
                              <p id="donation-purpose">
                              {item.id==user.icsid ? 
                              <input type="radio" name={ics_id}  id={ics_id} value={ics_id}  defaultChecked={true} />
                              :
                              <input type="radio" onChange={handelProfiles} name={ics_id}  id={ics_id} value={item?.id}  />
                              }
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">
                                
                              {item?.name}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Address :</h5>
                              <p id="donation-name">
                                
                                {item?.address.line1 +' '+ item?.address.line2 + ' '+ item?.address.line3}
                                </p>                        
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Action :</h5>
                              <p id="donation-name">
                                
                              <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={() => link_id ? navigate(`/update-profile/${item.id+'?link='+link_id}`) : navigate(`/update-profile/${item.id}`)}
                  >
                    Edit Profile
                  </button>
                                </p>                        
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  ))}

                  
                </div>
              </div>
            </div>



            <div className="life-membership-table">
              <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Relatives</h5>
              <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
                <div className="row justify-content-evenly align-items-center">
                 
                  <div className="col-lg-3">
                    <h5>Name</h5>
                  </div>
                  <div className="col-lg-3">
                    <h5>Relation</h5>
                  </div>

                  <div className="col-lg-3">
                    <h5>DOB</h5>
                  </div>
                  <div className="col-lg-3">
                    <h5>MA</h5>
                  </div>
                  {/* <div className="col-lg-3">
                    <h5>Address</h5>
                  </div> */}
                  {/* <div className="col-lg-4">
                    <h5>Action</h5>
                  </div> */}
                  
                </div>
              </div>
              <div className="table-content pt-2 pt-lg-0">
                <div className="row">
{ics_data?.find(dat => dat.id === user.icsid)?.relatives?.map((item, i) => (
 // console.log(user.icsid),
  //console.log(item?.ind2_name),

  <div key={item.ind1_ICSID} className="col-lg-12 col-md-6 ">
    <div className="table-row py-lg-4 py-3">
                        <div className="row align-items-center">
                          
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">
                                
                              {item?.ind1_ICSID == user.icsid ?  item?.ind2_name :  item?.ind1_name}
                              </p>
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">Relation :</h5>
                              <p id="donation-name">
                                
                              {item.relation === 'Wife' ? 'Spouse' : item.relation}
                              </p>
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">DOB :</h5>
                              <p id="donation-name">
                                
                              {item?.ind1_dob}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-field">
                              <h5 className="form-title">MA :</h5>
                              <p id="donation-name">
                                
                              {item?.ind1_ma}
                              </p>
                            </div>
                          </div>

                          

                          {/* <div className="col-lg-4">
                            <div className="form-field">
                              <h5 className="form-title">Action :</h5>
                              <p id="donation-name">
                                
                              <button
                    className="custom-btn-cls box-hover text-uppercase"
                    onClick={() => navigate(`/update-profile/${item.ind1_ICSID}`)}
                  >
                    Edit Profile
                  </button>
                                </p>                        
                            </div>
                          </div> */}
                          
                        </div>
                      </div>
  </div>
))}

                  
                </div>
              </div>
            </div>
          
          {/* {
            lifeMembershipDetails?.length > 0 &&
            <div className="life-membership-table">
              <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Life Membership</h5>
              <div className="table-heading py-lg-4 py-2 d-none d-lg-block">
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-lg-2">
                    <h5>Purpose</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Date</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Status</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Name</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Amount</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Download</h5>
                  </div>
                </div>
              </div>
              <div className="table-content pt-2 pt-lg-0">
                <div className="row">
                  {lifeMembershipDetails?.map((lifemember, i) => (
                    <div key={lifemember?._id} className="col-lg-12 col-md-6 ">
                      <div className="table-row py-lg-4 py-3">
                        <div className="row align-items-center">
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Purpose :</h5>
                              <p id="donation-purpose">
                                Life Membership
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Date :</h5>
                              <p id="donation-date">
                                
                                {moment(lifemember?.createdAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Status :</h5>
                                  <p id="donation-status" className={`text-uppercase ${lifemember?.paymentStatus === 'success' ? 'success' : 'failed'}`}>
                                    {lifemember?.paymentStatus === 'failed' ? 'Unsuccessful' : lifemember?.paymentStatus}
                                  </p>                          
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">
                                {lifemember?.firstname} {lifemember?.lastname}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Amount :</h5>
                              <p id="donation-amount">₹ {lifemember?.totalAmount ? lifemember?.totalAmount : lifemember?.amount}</p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field flex-column d-inline-flex">
                              {
                                lifemember?.paymentStatus === 'success' &&
                                <>
                                  <h5 className="form-title">Download :</h5>
                                  <div className="download-btns">
                                    {((lifemember.pan && lifemember.address && lifemember.pin && lifemember.donationVersion !== 'new') || (lifemember.eightyGGenerated)) ? (
                                      <button
                                        onClick={() => handleFileDownload(lifemember._id)}
                                        className=" ms-0 custom-btn-cls box-hover "
                                      >
                                        80G <HiOutlineDownload />
                                        <span>Download 80G certificate</span>
                                      </button>
                                    ) : (
                                      <>
                                        <button
                                          onClick={() =>
                                            navigate(
                                              `/update-details?update-pan=${lifemember._id}&donation-type=lifeMembership&redirect=/dashboard`
                                            )
                                          }
                                          className=" ms-0 custom-btn-cls box-hover "
                                        >
                                          80G <HiOutlineDownload />
                                          <span>Download 80G certificate</span>
                                        </button>
                                      </>
                                    )}
                                  
                                  </div>
                                </>
                              }
                              {
                                lifemember?.paymentStatus === 'failed' &&
                                  <a href={`/life-membership?lifeMembershipId=${lifemember._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                    Retry
                                  </a>
                              }
                              {
                                lifemember?.paymentStatus === 'pending' && <span className="processing">Processing</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          } 
           {
            donations?.length > 0 &&
            <div className="donation-table">
               <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Donations</h5>
              <div className="table-heading py-4 d-none d-lg-block">
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-lg-2">
                    <h5>Purpose</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Date</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Status</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Name</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Amount</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Download</h5>
                  </div>
                </div>
              </div>
              <div className="table-content pt-2 pt-lg-0">
                <div className="row">
                  {donations?.map((donation, i) => (
                    <div key={donation._id} className="col-lg-12 col-md-6 ">
                      <div className="table-row py-4">
                        <div className="row align-items-center">
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Purpose :</h5>
                              <p id="donation-purpose">
                                
                                {donation?.type.split("|")[0]}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Date :</h5>
                              <p id="donation-date">
                                
                                {moment(donation?.createdAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Status :</h5>
                                  <p id="donation-status" className={`text-uppercase ${donation?.paymentStatus === 'success' ? 'success' : 'failed'}`}>
                                    {donation?.paymentStatus === 'failed' ? 'Unsuccessful' : donation?.paymentStatus}
                                  </p>                          
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">
                                
                                {donation?.name}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Amount :</h5>
                              <p id="donation-amount">₹ {donation?.totalAmount ? donation?.totalAmount : donation?.amount}</p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field flex-column d-inline-flex">
                              {
                                donation?.paymentStatus === 'success' &&
                                <>
                                  <h5 className="form-title">Download :</h5>
                                  <div className="download-btns">
                                    {((donation.pan && donation.address && donation.pin && donation.eightyGNo && donation.donationVersion !== 'new') || (donation?.eightyGGenerated)) ? (
                                      <button
                                        onClick={() => handleFileDownload(donation._id)}
                                        className=" ms-0 custom-btn-cls box-hover "
                                      >
                                        80G <HiOutlineDownload />
                                        <span>Download 80G certificate</span>
                                      </button>
                                    ) : (
                                      <>
                                        {donation.model == "campaign" ? (
                                          <button
                                            onClick={() =>
                                              navigate(
                                                `/update-details?update-pan=${donation._id}&donation-type=campaign&redirect=/dashboard`
                                              )
                                            }
                                            className=" ms-0 custom-btn-cls box-hover "
                                          >
                                            80G <HiOutlineDownload />
                                            <span>Download 80G certificate</span>
                                          </button>
                                        ) : (
                                          <button
                                            onClick={() =>
                                              navigate(
                                                `/update-details?update-pan=${donation._id}&donation-type=donation&redirect=/dashboard`
                                              )
                                            }
                                            className=" ms-0 custom-btn-cls box-hover "
                                          >
                                            80G <HiOutlineDownload />
                                            <span>Download 80G certificate</span>
                                          </button>
                                        )}
                                      </>
                                    )}
                                    {(donation?.donationType?.hasCertificate && donation?.donationType?.isFoodForLife) && (
                                      <button
                                        onClick={() => handleImgDownload(donation._id)}
                                        className="custom-btn-cls box-hover"
                                      >
                                        Annadaan <HiOutlineDownload />
                                        <span>Annadaan certificate</span>
                                      </button>
                                    )}
                                  </div>
                                </>
                              }
                              {
                                donation?.paymentStatus === 'failed' &&
                                <>
                                      {
                                          donation.model === 'campaign' &&
                                          <a href={`/campaign-donation/${donation.type.toLowerCase().split(' ').join('-')}/${donation.amount}?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                            Retry
                                          </a>
                                        } 

                                        {
                                          donation.model === 'donationType' &&
                                          <a href={`/donation-form?donationId=${donation._id}`} className='custom-btn-cls box-hover m-0 text-center'>
                                            Retry
                                          </a>
                                        } 
                                </>
                              }
                              {
                                donation?.paymentStatus === 'pending' && <span className="processing">Processing</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <nav className={`${lastPage <= 1 ? "d-none" : ""}`}>
                <ul className="pagination">
                  <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                    <button
                      type="button"
                      className="page-link arrow"
                      onClick={() => setPage(page - 1)}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 256 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                      </svg>
                    </button>
                  </li>
                  <li className={`page-item ${page === 1 ? "d-none" : ""}`}>
                    <a className="page-link" onClick={() => setPage(page - 1)}>
                      {page - 1}
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link active">{page}</a>
                  </li>
                  <li className={`page-item ${page === lastPage ? "d-none" : ""}`}>
                    <a className="page-link" onClick={() => setPage(page + 1)}>
                      {page + 1}
                    </a>
                  </li>
                  <li
                    className={`page-item ${page === lastPage ? "disabled" : ""}`}
                  >
                    <button
                      type="button"
                      className="page-link arrow"
                      onClick={() => setPage(page + 1)}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 256 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          } */}
          {
            purchases?.length > 0 &&
            <div className="donation-table mt-5">
            {/* <h5 className='table-title mt-lg-5 mt-3 mb-lg-3 mb-0'>Purchases</h5> */}
              {/* <div className="table-heading py-4 d-none d-lg-block">
                <div className="row justify-content-evenly align-items-center">
                  <div className="col-lg-4">
                    <h5>Purpose</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Date</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Status</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Name</h5>
                  </div>
                  <div className="col-lg-2">
                    <h5>Amount</h5>
                  </div>
                
                </div>
              </div> 
               <div className="table-content pt-2 pt-lg-0">
                <div className="row">
                  {purchases?.map((purchase, i) => (
                    <div key={purchase._id} className="col-lg-12 col-md-6 ">
                      <div className="table-row py-4">
                        <div className="row align-items-center">
                          <div className="col-lg-4">
                            <div className="form-field">
                              <h5 className="form-title">Purpose :</h5>
                              <p id="donation-purpose">
                              Premium Sandalwood Agarbatti                                
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Date :</h5>
                              <p id="donation-date">
                                
                                {moment(purchase?.createdAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Status :</h5>
                                  <p id="donation-status" className={`text-uppercase ${purchase?.paymentStatus === 'success' ? 'success' : 'failed'}`}>
                                    {purchase?.paymentStatus === 'failed' ? 'Unsuccessful' : purchase?.paymentStatus}
                                  </p>                          
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Name :</h5>
                              <p id="donation-name">
                                
                                {purchase?.name}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-field">
                              <h5 className="form-title">Amount :</h5>
                              <p id="donation-amount">₹ {purchase?.totalAmount ? purchase?.totalAmount : purchase?.amount}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div> 
              <nav className={`${lastPage <= 1 ? "d-none" : ""}`}>
                <ul className="pagination">
                  <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                    <button
                      type="button"
                      className="page-link arrow"
                      onClick={() => setPage(page - 1)}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 256 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"></path>
                      </svg>
                    </button>
                  </li>
                  <li className={`page-item ${page === 1 ? "d-none" : ""}`}>
                    <a className="page-link" onClick={() => setPage(page - 1)}>
                      {page - 1}
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link active">{page}</a>
                  </li>
                  <li className={`page-item ${page === lastPage ? "d-none" : ""}`}>
                    <a className="page-link" onClick={() => setPage(page + 1)}>
                      {page + 1}
                    </a>
                  </li>
                  <li
                    className={`page-item ${page === lastPage ? "disabled" : ""}`}
                  >
                    <button
                      type="button"
                      className="page-link arrow"
                      onClick={() => setPage(page + 1)}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 256 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path>
                      </svg>
                    </button>
                  </li>
                </ul>
              </nav> */}
            </div>
          }
        </div>
      </section>
    </>
  );
};

export default Dashboard;
