import React, { useRef } from "react";
import PageHeader from "../components/common/PageHeader";
import MetaData from "../helpers/MetaData";
import { useForm } from "react-hook-form";
import { useState } from "react";
import notification from "../helpers/notification";
import axios from "axios";
import ApiService from "../api/ApiService";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../context/AppContext";

const ShopCheckout = () => {
  const { id } = useParams();
  const search = useLocation().search;
  const [searchParams, setSearchParams] = useSearchParams();
  const price = new URLSearchParams(search).get("price");
  const purchaseId = searchParams.get("shopId");
  const [count, setCount] = useState(1);
  const { user } = useContext(AppContext);
  const [selectedState, setSelectedState] = useState("");
  const [selectedValue, setSelectedStateValue] = useState(0);
  const [selectedStaticValue, setSelectedStaticValue] = useState(0);
  const [selectedLabelValue, setSelectedOptionValue] = useState("");
  const [shopDetails, setShopDetails] = useState({});

  let totalPrice = 0;

  totalPrice = price * count;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      amount: id === "2" ? totalPrice : 1,
    },
  });

  const stateOptions = [
    { stateid: 1, label: "Andhra Pradesh", value: 360 },
    { stateid: 2, label: "Arunachal Pradesh", value: 450 },
    { stateid: 3, label: "Assam", value: 450 },
    { stateid: 4, label: "Bihar", value: 360 },
    { stateid: 5, label: "Chhattisgarh", value: 360 },
    { stateid: 6, label: "Goa", value: 360 },
    { stateid: 7, label: "Gujarat", value: 360 },
    { stateid: 8, label: "Haryana", value: 360 },
    { stateid: 9, label: "Himachal Pradesh", value: 360 },
    { stateid: 10, label: "Jammu and Kashmir", value: 450 },
    { stateid: 11, label: "Jharkhand", value: 360 },
    { stateid: 12, label: "Karnataka", value: 360 },
    { stateid: 13, label: "Kerala", value: 360 },
    { stateid: 14, label: "Madhya Pradesh", value: 360 },
    { stateid: 15, label: "Maharashtra", value: 275 },
    { stateid: 16, label: "Manipur", value: 450 },
    { stateid: 17, label: "Meghalaya", value: 450 },
    { stateid: 18, label: "Mizoram", value: 450 },
    { stateid: 19, label: "Nagaland", value: 450 },
    { stateid: 20, label: "Odisha", value: 360 },
    { stateid: 21, label: "Punjab", value: 360 },
    { stateid: 22, label: "Rajasthan", value: 360 },
    { stateid: 23, label: "Sikkim", value: 360 },
    { stateid: 24, label: "Tamil Nadu", value: 360 },
    { stateid: 25, label: "Telangana", value: 360 },
    { stateid: 26, label: "Tripura", value: 450 },
    { stateid: 27, label: "Uttar Pradesh", value: 360 },
    { stateid: 28, label: "Uttarakhand", value: 360 },
    { stateid: 29, label: "West Bengal", value: 360 },
  ];

  const reCaptChaRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isRetryPayment, setIsRetryPayment] = useState(false);

  const fetchStateCity = async (pin) => {
    try {
      let url = `https://api.postalpincode.in/pincode/${pin}`;

      let config = {
        method: "GET",
        url,
      };
      const { data } = await axios(config);
      if (data[0].Status !== "Success") {
        notification("warning", "Enter valid PIN code!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchShopDetailsById = async () => {
    try {
      const response = await ApiService.fetchShopById(id);
      if (response.success) {
        setShopDetails(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  let totalCharge = 0;
  if (count > 2 || count === 2) {
    totalCharge = 100;
  } else {
    totalCharge = 0;
  }

  const handleStateChange = (event) => {
    const selectedStateId = parseInt(event.target.value);
    const state = stateOptions.find((option) => option.stateid === selectedStateId);

    const selectedValue = state.value;
    const selectedStateIdValue = state.stateid;

    const finalPrice = +selectedValue + +totalCharge;
    setSelectedState(selectedStateIdValue);
    setSelectedStateValue(finalPrice);
    setSelectedStaticValue(selectedValue);
    setSelectedOptionValue(state.label);
  };

  let finalDeliveryCharge = +selectedValue + +totalCharge;

  totalPrice = Math.round(price * count) + Math.round(finalDeliveryCharge);

  const handlePurchase = async (values) => {
    try {
      setLoading(true);

      const token = await reCaptChaRef.current.executeAsync();
      values.token = token;
      values.amount = id === "2" ? totalPrice : shopDetails?.price * count;
      values.count = count;
      values.userType = user ? "user" : "guest";
      values.productName = id === "2" ? values.productName : shopDetails.title;
      localStorage.setItem("order_details", id);
      if (id === "2") {
        values.state = selectedLabelValue;
      }

      const res = await ApiService.purchaseShopProduct(values);
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        currency: res.currency,
        amount: res.amount,
        name: process.env.REACT_APP_PROJECT_NAME,
        description: "Help Us to Help the Society",
        image: `${process.env.REACT_APP_BACKEND_URL}/assets/img/logo.png`,
        order_id: res.id,
        handler: function (response) {
          navigate(`/purchase-success/${res.shopId}`, { replace: true });
        },
        prefill: {
          name: res.name ? res.name : "",
          email: res.email ? res.email : "",
          contact: res.phone ? res.phone : "",
        },
        notes: {
          type: "shop",
        },
        theme: {
          color: "#FFD370",
        },
        modal: {
          ondismiss: async function () {
            const cancelPurchase = await ApiService.cancelPurchase(res.id);
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on("payment.failed", function (response) {
        navigate(`/purchase-failed?id=${res.shopId}`, { replace: true });
      });
      setLoading(false);
      reCaptChaRef.current.reset();
    } catch (error) {
      setLoading(false);
      console.log(error);
      notification("error", error.response.data.message);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const fetchPurchaseDetails = async () => {
    try {
      const res = await ApiService.fetchPurchaseDetails(purchaseId);
      reset({
        name: res.name,
        phone: res.phone,
        email: res.email,
        address: res.address,
        city: res.city,
        pin: res.pin,
        amount: Number(res.amount) / id === "2" ? totalPrice : shopDetails?.price,
      });
      setCount(Number(res.amount) / id === "2" ? totalPrice : shopDetails?.price);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    fetchShopDetailsById();
  }, []);

  useEffect(() => {
    if (user && !isRetryPayment) {
      reset({
        name: user.name ? user.name : "",
        phone: user.phone ? user.phone : "",
        email: user.email ? user.email : "",
        address: user.address ? user.address : "",
        pin: user.pin ? user.pin : "",
        city: user.city ? user.city : "",
      });
    }
  }, [user]);

  useEffect(() => {
    if (purchaseId) {
      setIsRetryPayment(true);
      fetchPurchaseDetails();
    }
  }, [purchaseId]);

  return (
    <>
      <MetaData title={id === "2" ? "Sri Chaitanya Charitamrita  - ISKCON of Bhiwandi" : shopDetails?.title + "  - ISKCON of Bhiwandi"} />
      <PageHeader title={id === "2" ? "Sri Chaitanya Charitamrita" : shopDetails?.title} imgSrc="/images/page-header/24.jpg" />
      <section className="donation-form-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="heading">
                <h2 className="head-1">{id === "2" ? "Sri Chaitanya Charitamrita" : shopDetails?.title}</h2>
                <p className="b-line">
                  {id === "2"
                    ? "Chaitanya Charitamrita, a sacred scripture, illuminates the divine pastimes and teachings of Sri Chaitanya Mahaprabhu, who bestowed the chanting of the holy names (Harinaam) upon fallen souls in this Kali Yuga, embodying the essence of devotion to Lord Krishna."
                    : shopDetails?.description}
                </p>
              </div>
            </div>
          </div>
          <form className="shop-form mt-5" onSubmit={handleSubmit(handlePurchase)}>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="donate-img">
                  {id === "2" ? (
                    <figure className="mb-0">
                      <img src="/images/common/sliderimage.png" alt="" />
                    </figure>
                  ) : (
                    <figure className="mb-0">
                      {shopDetails?.galleryImages && (
                        <img src={process.env.REACT_APP_S3_URL + "/uploads/shop/gallery-images/" + shopDetails?.galleryImages[0]} alt="" />
                      )}
                    </figure>
                  )}
                </div>
              </div>
              <div className="col-lg-8 mt-lg-0 mt-4">
                <div className="row">
                  {id === "2" && (
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="productName">Product Name</label>
                        <input
                          type="text"
                          value={
                            price === "3000"
                              ? "Sri Chaitanya Charitamrita English"
                              : price === "3700"
                              ? "Sri Chaitanya Charitamrita Hindi"
                              : price === "2450"
                              ? "Sri Chaitanya Charitamrita Marathi"
                              : price === "2300"
                              ? "Sri Chaitanya Charitamrita Gujarati"
                              : price === "2500"
                              ? "Sri Chaitanya Charitamrita Bengali"
                              : price === "1700"
                              ? "Sri Chaitanya Charitamrita Odiya"
                              : price === "2490"
                              ? "Sri Chaitanya Charitamrita Telugu"
                              : price === "3200"
                              ? "Sri Chaitanya Charitamrita Tamil"
                              : price === "4900"
                              ? "Sri Chaitanya Charitamrita Kannada"
                              : price === "4200"
                              ? "Sri Chaitanya Charitamrita Malayalam"
                              : ""
                          }
                          placeholder="Product Name"
                          {...register("productName", {
                            required: true,
                          })}
                        />
                        {errors.productName?.type === "required" && <span className="error text-danger">This is required</span>}
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-part">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        {...register("name", {
                          required: true,
                        })}
                      />
                      {errors.name?.type === "required" && <span className="error text-danger">This is required</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-part">
                      <label htmlFor="phone">Phone</label>
                      <input
                        type="tel"
                        placeholder="Enter Your Phone"
                        maxLength={10}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("phone", {
                          required: true,
                          pattern: {
                            value: /^[6-9]\d*$/,
                            message: "Please provide valid phone number",
                          },
                          minLength: {
                            value: 10,
                            message: "Phone number must be minimum 10 digit",
                          },
                        })}
                      />
                      {errors.phone?.type === "required" && <span className="error text-danger">This is required</span>}
                      {errors.phone && <span className="error text-danger">{errors.phone.message}</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-part">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        placeholder="Enter Your Email"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                      />
                      {errors.email?.type === "required" && <span className="error text-danger">This is required</span>}
                      {errors.email && <span className="error text-danger">{errors.email.message}</span>}
                    </div>
                  </div>
                  {id === "2" ? (
                    <>
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="streetName">Street Name</label>
                          <textarea
                            {...register("streetName", {
                              required: true,
                            })}
                            placeholder="Enter  Street Name"
                          ></textarea>
                          {errors.streetName?.type === "required" && <span className="error text-danger">This is required</span>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-part">
                          <label htmlFor="areaName">Area Name</label>
                          <textarea
                            {...register("areaName", {
                              required: true,
                            })}
                            placeholder="Enter  Area"
                          ></textarea>
                          {errors.areaName?.type === "required" && <span className="error text-danger">This is required</span>}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="address">Address</label>
                        <textarea
                          {...register("address", {
                            required: true,
                          })}
                          placeholder="Enter Your Address"
                        ></textarea>
                        {errors.address?.type === "required" && <span className="error text-danger">This is required</span>}
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-part">
                      <label htmlFor="city">City</label>
                      <input
                        type="text"
                        placeholder="Enter Your City"
                        {...register("city", {
                          required: true,
                        })}
                      />
                      {errors.city?.type === "required" && <span className="error text-danger">This is required</span>}
                    </div>
                  </div>
                  {id === "2" && (
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="state">State</label>
                        <select id="state" name="state" {...register("state", { required: true })} value={selectedState} onChange={handleStateChange}>
                          <option value="" disabled selected>
                            Select State
                          </option>
                          {stateOptions.map((option) => (
                            <option key={option.stateid} value={option.stateid}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.state?.type === "required" && <span className="error text-danger">This is required</span>}
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-part">
                      <label htmlFor="pin">PIN</label>
                      <input
                        type="text"
                        minLength={6}
                        maxLength={6}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        {...register("pin", {
                          required: true,
                          pattern: {
                            value: /^\d+$/,
                            message: "PIN code must be number.",
                          },
                          onChange: (e) => {
                            if (e.target.value.length === 6) {
                              fetchStateCity(e.target.value);
                            }
                          },
                        })}
                        placeholder="PIN"
                      />
                      {errors.pin?.type === "required" && <span className="error text-danger">This is required</span>}
                      {errors.pin && <small className="text-danger error">{errors.pin.message}</small>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-part">
                      <label htmlFor="amount">Quantity</label>
                      <div className="shop-count">
                        <div className="amount">
                          {id === "2" ? (
                            <>
                              <img src="/images/shop/gaura_purima.jpeg" alt="" />
                              <span>{price.toLocaleString("en-IN", { maximumFractionDigits: 0, style: "currency", currency: "INR" })}</span>
                            </>
                          ) : (
                            <>
                              {shopDetails?.galleryImages && (
                                <img
                                  src={process.env.REACT_APP_S3_URL + "/uploads/shop/gallery-images/" + shopDetails?.galleryImages[0]}
                                  alt="shop-image"
                                />
                              )}
                            </>
                          )}
                          <span>{shopDetails?.price}</span>
                        </div>

                        <div className="count-wrap">
                          {id === "2" ? (
                            <div className="count">
                              <button
                                type="button"
                                onClick={() => {
                                  setValue("amount", count - 1);
                                  setCount(count - 1);
                                  setSelectedStateValue(count === 2 ? selectedStaticValue : +selectedValue - +totalCharge);
                                }}
                                disabled={count <= 1 ? true : false}
                              >
                                <span>-</span>
                              </button>
                              <span className="showCount">{count}</span>
                              <button
                                type="button"
                                onClick={() => {
                                  setValue("amount", count + 1);
                                  setCount(count + 1);
                                  setSelectedStateValue(+selectedValue + totalCharge);
                                }}
                              >
                                <span>+</span>
                              </button>
                            </div>
                          ) : (
                            <div className="count">
                              <button
                                type="button"
                                onClick={() => {
                                  setValue("amount", count - 1);
                                  setCount(count - 1);
                                }}
                                disabled={count <= 1 ? true : false}
                              >
                                <span>-</span>
                              </button>
                              <span className="showCount">{count}</span>
                              <button
                                type="button"
                                onClick={() => {
                                  setValue("amount", count + 1);
                                  setCount(count + 1);
                                }}
                              >
                                <span>+</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {id === "2" && (
                    <div className="col-md-6">
                      <div className="form-part">
                        <label htmlFor="deliveryCharge">Delivery Charge</label>
                        <input
                          type="text"
                          placeholder="Delivery Charge"
                          value={finalDeliveryCharge}
                          {...register("deliveryCharge", { required: true })}
                        />
                        {errors.deliveryCharge?.type === "required" && <span className="error text-danger">This is required</span>}
                      </div>
                    </div>
                  )}
                  <div className="col-12">
                    <div className="col-12">
                      <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} ref={reCaptChaRef} size="invisible" />
                    </div>
                  </div>
                  <div className="col-12 ">
                    <button type="submit" className="box-hover custom-btn-cls donation_btn ms-0 donate-now-clicked-form">
                      {loading ? (
                        <div className="d-flex align-items-center">
                          <div className="spinner-border text-light" role="status"></div>
                          <div className="ms-2">PURCHASING...</div>
                        </div>
                      ) : (
                        <>
                          PAY ₹
                          {id === "2"
                            ? totalPrice.toLocaleString("en-IN", { maximumFractionDigits: 0, style: "currency", currency: "INR" })
                            : shopDetails.price * count}
                          /-
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default ShopCheckout;
